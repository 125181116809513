import { createAction } from 'redux-actions';
import { createAsyncAction } from '../helpers';

export const accountFacilityPinCodeAsyncAction = createAsyncAction(
	'ACCOUNT_FACILITY_PIN_CODE'
);

export const accountUpdateProfileAsyncAction = createAsyncAction(
	'ACCOUNT_UPDATE_PROFILE'
);

export const accountGetProfileAsyncAction = createAsyncAction(
	'ACCOUNT_GET_PROFILE'
);

export const accountPostVerifyPinAsyncAction = createAsyncAction(
	'ACCOUNT_POST_VERIFY_PIN'
);

export const accountGetLedgersAsyncAction = createAsyncAction(
	'ACCOUNT_GET_LEDGERS'
);

export const accountGetPointsHistoryAsyncAction = createAsyncAction(
	'ACCOUNT_GET_POINTS_HISTORY'
);

export const decrementNotificiationOnReadAction = createAction(
	'DECREMENT_NOTIFICATION_ON_READ'
);
