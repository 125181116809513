import { createAction } from 'redux-actions';
import { createAsyncAction } from '../helpers';

export const authLoginAsyncAction = createAsyncAction('AUTH_LOGIN');

export const authLogoutAsyncAction = createAsyncAction('AUTH_LOGOUT');

export const authSetRememberMe = createAction('AUTH_SET_REMEMBER_ME');

export const authChangePasswordAsyncAction = createAsyncAction(
	'AUTH_CHANGE_PASSWORD'
);

export const authPostFirstTimeLoginEmailAsyncAction = createAsyncAction(
	'AUTH_POST_FIRST_TIME_LOGIN_EMAIL'
);

export const authPostFirstTimeLoginOtpAsyncAction = createAsyncAction(
	'AUTH_POST_FIRST_TIME_LOGIN_OTP'
);

export const authPostFirstTimeLoginPasswordAsyncAction = createAsyncAction(
	'AUTH_POST_FIRST_TIME_LOGIN_PASSWORD'
);

export const authPutRefreshTokenAsyncAction = createAsyncAction(
	'AUTH_PUT_REFRESH_TOKEN'
);

export const authPostForgotPasswordAsyncAction = createAsyncAction(
	'AUTH_POST_FORGOT_PASSWORD'
);

export const authPostGetVerifyPinAsyncAction = createAsyncAction(
	'AUTH_POST_GET_VERIFY_PIN'
);
