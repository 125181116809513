import {
	paymentAddPaymentCardAsyncAction,
	paymentDeletePaymentCardAsyncAction,
	paymentGetPaymentCardsAsyncAction,
	paymentGetInvoicesAsyncAction,
	paymentUpdatePaymentCardAsyncAction,
	paymentGetPaymentHistoryAsyncAction,
	paymentGetPaymentDetailsAsyncActionAsync,
	paymentMakePaymentAsyncAction,
	paymentMakePayment2AsyncAction,
	paymentGetMyBillsAsyncAction,
	paymentGetPaymentTransactionAsyncAction,
	paymentGetReceiptsAsyncAction,
} from './paymentActions';
import { createEpic } from '../helpers';

export const masterGetEpic = createEpic(
	paymentGetInvoicesAsyncAction,
	'payment',
	'getInvoices',
	true
);

export const getCardsEpic = createEpic(
	paymentGetPaymentCardsAsyncAction,
	'payment',
	'getCards',
	true
);

export const addCardEpic = createEpic(
	paymentAddPaymentCardAsyncAction,
	'payment',
	'addCard',
	true
);

export const updateCardEpic = createEpic(
	paymentUpdatePaymentCardAsyncAction,
	'payment',
	'updateCard',
	true
);

export const getPaymentHistoryEpic = createEpic(
	paymentGetPaymentHistoryAsyncAction,
	'payment',
	'getPaymentHistory',
	true
);

export const paymentGetPaymentDetailsAsyncActionEpic = createEpic(
	paymentGetPaymentDetailsAsyncActionAsync,
	'payment',
	'paymentGetPaymentDetailsAsyncAction',
	true
);

export const makePaymentEpic = createEpic(
	paymentMakePaymentAsyncAction,
	'payment',
	'makePayment',
	true
);

export const makePayment2Epic = createEpic(
	paymentMakePayment2AsyncAction,
	'payment',
	'makePayment2',
	true
);

export const deleteCardEpic = createEpic(
	paymentDeletePaymentCardAsyncAction,
	'payment',
	'deleteCard',
	true
);

export const getPaymentGetMyBillsEpic = createEpic(
	paymentGetMyBillsAsyncAction,
	'payment',
	'getMyBills',
	true
);

export const getPaymentGetPaymentTransactionEpic = createEpic(
	paymentGetPaymentTransactionAsyncAction,
	'payment',
	'getPaymentTransaction',
	true
);

export const getPaymentGetReceiptsEpic = createEpic(
	paymentGetReceiptsAsyncAction,
	'payment',
	'getReceipts',
	true
);
