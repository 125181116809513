import {
	rewardGetFeaturedRewardListAsyncAction,
	rewardGetRewardListAsyncAction,
	rewardRedeemRewardAsyncAction,
	rewardGetMyWalletAsyncAction,
	rewardGetMyExtraSpaceWalletAsyncAction,
} from './rewardActions';
import { createEpic } from '../helpers';

export const rewardGetFeaturedRewardsEpic = createEpic(
	rewardGetFeaturedRewardListAsyncAction,
	'reward',
	'getFeaturedRewards',
	true
);

export const rewardGetRewardsByCategoryEpic = createEpic(
	rewardGetRewardListAsyncAction,
	'reward',
	'getRewardsByCategory',
	true
);

export const redeemRewardEpic = createEpic(
	rewardRedeemRewardAsyncAction,
	'reward',
	'redeemReward',
	true
);

export const getMyWalletEpic = createEpic(
	rewardGetMyWalletAsyncAction,
	'reward',
	'getMyWallet',
	true
);

export const getMyExtraSpaceWalletEpic = createEpic(
	rewardGetMyExtraSpaceWalletAsyncAction,
	'reward',
	'getMyExtraSpaceWallet',
	true
);
