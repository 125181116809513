import { createEpic } from '../helpers';

import {
	notificationGetListAsyncAction,
	notificationMarkAsReadAsyncAction,
	notificationDeleteAsyncAction,
} from './notificationsActions';

export const getNotificationsEpic = createEpic(
	notificationGetListAsyncAction,
	'notifications',
	'getNotifications',
	true
);

export const markNotificationAsReadEpic = createEpic(
	notificationMarkAsReadAsyncAction,
	'notifications',
	'markNotificationAsRead',
	true
);

export const deleteNotificationsEpic = createEpic(
	notificationDeleteAsyncAction,
	'notifications',
	'deleteNotifications',
	true
);
