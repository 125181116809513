import * as auth from './auth/auth.service';
import * as account from './account/account.service';
import * as posts from './posts/posts.service';
import * as master from './master/master.service';
import * as reward from './reward/reward.service';
import * as payment from './payment/payment.service';
import * as notifications from './notifications/notifications.service';
import * as feedback from './feedback/feedback.service';
import * as faq from './faq/faq.service';
import * as content from './content/content.service';

export default {
	auth,
	account,
	posts,
	master,
	reward,
	payment,
	notifications,
	feedback,
	faq,
	content,
};
