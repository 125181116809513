import {
	handleActions,
	combineActions,
	ActionFunctionAny,
	Action,
} from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type {
	State,
	Payload,
	NotificationsResponse,
} from 'src/dtos/notification/notification-reducer.dto';
import type {
	Notification,
	GetNotificationsPayload,
	MarkNotificationReadPayload,
	DeleteNotificationsPayload,
} from 'src/dtos/notification/notification.dto';

import {
	notificationGetListAsyncAction,
	notificationMarkAsReadAsyncAction,
	notificationDeleteAsyncAction,
	notificationSelectAction,
} from './notificationsActions';

const initialState: State = {
	selectedNotification: null,

	notifications: {
		currentPage: 1,
		loading: false,
		success: false,
		error: '',
		data: null,
	},

	action: {
		loading: false,
		success: false,
		error: '',
		data: {},
	},
};

const getNotificationsAfterRead = (
	notifications: Array<Notification>,
	readNotiId: string
) => {
	const newNotifications = [...notifications];

	const index = newNotifications.findIndex(({ id }) => id === readNotiId);

	if (index !== -1) {
		newNotifications[index].isRead = true;
	}

	return newNotifications;
};

const getNotificationsAfterDelete = (
	notifications: Array<Notification>,
	deletedNotiIds = []
) => notifications.filter(({ id }) => !deletedNotiIds.includes(id));

export const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>> | ReturnType<typeof combineActions>,
		(state: State, { payload }) => State
	>([
		[
			notificationGetListAsyncAction.request,
			(state, { payload }: { payload: GetNotificationsPayload }) => ({
				...state,
				notifications: {
					...state.notifications,
					loading: true,
					currentPage: payload.page,
					data: {
						...state.notifications.data,
						...(payload.page === 1 ? { dataList: [] } : {}),
					},
				},
			}),
		],
		[
			notificationGetListAsyncAction.success,
			(state, { payload }: { payload: NotificationsResponse }) => ({
				...state,
				notifications: {
					...state.notifications,
					loading: false,
					success: true,
					data: {
						...payload.data,
						dataList: state.notifications.data.dataList.concat(
							payload.data.dataList
						),
					},
				},
			}),
		],
		[
			notificationGetListAsyncAction.failure,
			(state, { payload }: { payload: NotificationsResponse }) => ({
				...state,
				notifications: {
					...state.notifications,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			notificationMarkAsReadAsyncAction.request,
			(state, { payload }: { payload: MarkNotificationReadPayload }) => ({
				...state,
				action: {
					...state.action,
					loading: true,
				},
				notifications: {
					...state.notifications,
					data: {
						...state.notifications.data,
						dataList: getNotificationsAfterRead(
							state.notifications.data.dataList,
							payload.id
						),
					},
				},
			}),
		],

		[
			notificationDeleteAsyncAction.request,
			(state, { payload }: { payload: DeleteNotificationsPayload }) => ({
				...state,
				action: {
					...state.action,
					loading: true,
				},
				notifications: {
					...state.notifications,
					data: {
						...state.notifications.data,
						dataList: getNotificationsAfterDelete(
							state.notifications.data.dataList,
							payload.notificationIds
						),
					},
				},
			}),
		],

		[
			combineActions(
				notificationMarkAsReadAsyncAction.success,
				notificationDeleteAsyncAction.success
			),
			(state, { payload }: { payload: Payload }) => ({
				...state,
				action: {
					...state.action,
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			combineActions(
				notificationMarkAsReadAsyncAction.failure,
				notificationDeleteAsyncAction.failure
			),
			(state, { payload }: { payload: Payload }) => ({
				...state,
				action: {
					...state.action,
					loading: false,
					success: false,
					data: payload.error_msg,
				},
			}),
		],

		[
			notificationSelectAction,
			(state, { payload }: { payload: Notification }) => ({
				...state,
				selectedNotification: payload,
			}),
		],
	])
);

export const notificationsReducer = persistReducer(
	{
		key: 'notifications',
		storage,
		whitelist: [],
		blacklist: ['notifications'],
	},
	handleActions(reducerMap, initialState)
);
