import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

interface LoadingProps extends Theme {
	size: number;
}

export const Loading = styled.div<LoadingProps>`
	display: inline-block;
	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;

	&:after {
		content: ' ';
		display: block;
		width: ${({ size }) => size - 16}px;
		height: ${({ size }) => size - 16}px;
		margin: 8px;
		border-radius: 50%;
		border: ${({ size }) => Math.ceil(size / 15)}px solid
			${({ color, theme }) => color || theme.colors.primary};
		border-color: ${({ theme, color }) => color || theme.colors.primary}
			transparent ${({ theme, color }) => color || theme.colors.primary}
			transparent;
		animation: rotate 1.2s linear infinite;
	}
`;

export const Centered = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
`;

export const Screen = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.8);
`;
