import {
	combineActions,
	handleActions,
	ActionFunctionAny,
	Action,
} from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/auth/auth-reducer.dto';

import {
	authLoginAsyncAction,
	authLogoutAsyncAction,
	authPostFirstTimeLoginEmailAsyncAction,
	authPostFirstTimeLoginOtpAsyncAction,
	authPostFirstTimeLoginPasswordAsyncAction,
	authPutRefreshTokenAsyncAction,
	authSetRememberMe,
	authChangePasswordAsyncAction,
	authPostForgotPasswordAsyncAction,
} from './authActions';

const initialState: State = {
	isRememberMe: true,

	authenticated: false,

	passwordUpdated: {
		success: false,
		error: '',
		loading: false,
	},

	token: '',

	refreshToken: '',

	login: {
		loading: false,
		success: false,
		error: '',
	},

	firstTimeLoginEmail: {
		loading: false,
		success: false,
		error: '',
	},

	firstTimeLoginOtp: {
		loading: false,
		success: false,
		error: '',
		token: '',
	},

	firstTimeLoginPassword: {
		loading: false,
		success: false,
		error: '',
	},

	forgotPassword: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},
};

const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>> | ReturnType<typeof combineActions>,
		(state: State, { payload }: { payload: Payload }) => any
	>([
		[
			authLoginAsyncAction.request,
			(state) => ({
				...state,
				login: { ...state.login, loading: true },
			}),
		],
		[
			combineActions(
				authLoginAsyncAction.success,
				authPutRefreshTokenAsyncAction.success
			),
			(state, { payload }) => {
				return {
					...state,
					token: payload.data.accessToken,
					refreshToken: payload.data.refreshToken,
					authenticated: true,
					login: {
						...state.login,
						loading: false,
					},
				};
			},
		],
		[
			authLoginAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				login: {
					loading: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			combineActions(
				authLogoutAsyncAction.success,
				authPutRefreshTokenAsyncAction.failure
			),
			(state) => ({
				...state,
				token: '',
				refreshToken: '',
				authenticated: false,
			}),
		],

		[
			authSetRememberMe,
			(state, { payload }) => ({ ...state, isRememberMe: payload }),
		],

		[
			authPostFirstTimeLoginEmailAsyncAction.request,
			(state) => ({
				...state,
				firstTimeLoginEmail: {
					...initialState.firstTimeLoginEmail,
					loading: true,
				},
				firstTimeLoginOtp: {
					...initialState.firstTimeLoginOtp,
				},
			}),
		],
		[
			authPostFirstTimeLoginEmailAsyncAction.success,
			(state) => ({
				...state,
				firstTimeLoginEmail: {
					...initialState.firstTimeLoginEmail,
					success: true,
					loading: false,
				},
			}),
		],
		[
			authPostFirstTimeLoginEmailAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				firstTimeLoginEmail: {
					...initialState.firstTimeLoginEmail,
					error: payload.error_msg,
					loading: false,
					success: false,
				},
			}),
		],

		[
			authPostFirstTimeLoginOtpAsyncAction.request,
			(state) => ({
				...state,
				firstTimeLoginOtp: {
					...initialState.firstTimeLoginOtp,
					loading: true,
				},
			}),
		],
		[
			authPostFirstTimeLoginOtpAsyncAction.success,
			(state, { payload }) => ({
				...state,
				firstTimeLoginOtp: {
					...initialState.firstTimeLoginOtp,
					success: true,
					token: payload.data.setToken,
					loading: false,
				},
			}),
		],
		[
			authPostFirstTimeLoginOtpAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				firstTimeLoginOtp: {
					...initialState.firstTimeLoginOtp,
					error: payload.error_msg,
					loading: false,
					success: false,
				},
			}),
		],

		[
			authPostFirstTimeLoginPasswordAsyncAction.request,
			(state) => ({
				...state,
				firstTimeLoginPassword: {
					...initialState.firstTimeLoginPassword,
					loading: true,
				},
			}),
		],
		[
			authPostFirstTimeLoginPasswordAsyncAction.success,
			(state, { payload }) => ({
				...state,
				firstTimeLoginPassword: {
					...initialState.firstTimeLoginPassword,
					success: true,
					loading: false,
				},
				token: payload.data.accessToken,
				refreshToken: payload.data.refreshToken,
				authenticated: true,
			}),
		],
		[
			authPostFirstTimeLoginPasswordAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				firstTimeLoginPassword: {
					...initialState.firstTimeLoginPassword,
					error: payload.error_msg,
					loading: false,
					success: false,
				},
			}),
		],

		[
			authChangePasswordAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				passwordUpdated: {
					...initialState.passwordUpdated,
					error: payload.error_msg,
					success: false,
					loading: false,
				},
			}),
		],
		[
			authChangePasswordAsyncAction.request,
			(state) => ({
				...state,
				passwordUpdated: {
					...initialState.passwordUpdated,
					loading: true,
				},
			}),
		],
		[
			authChangePasswordAsyncAction.success,
			(state) => ({
				...state,
				passwordUpdated: {
					...initialState.passwordUpdated,
					success: true,
					loading: false,
				},
			}),
		],

		[
			authPostForgotPasswordAsyncAction.request,
			(state) => ({
				...state,
				forgotPassword: {
					...state.forgotPassword,
					loading: true,
				},
			}),
		],
		[
			authPostForgotPasswordAsyncAction.success,
			(state, { payload }) => ({
				...state,
				forgotPassword: {
					...state.forgotPassword,
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			authPostForgotPasswordAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				forgotPassword: {
					...state.forgotPassword,
					loading: false,
					success: false,
					data: null,
					error: payload.error_msg,
				},
			}),
		],
	])
);

export const authReducer = persistReducer<State, any>(
	{
		key: 'auth',
		storage,
		whitelist: ['token', 'refreshToken', 'authenticated', 'isRememberMe'],
	},
	handleActions(reducerMap, initialState)
);
