import { Theme, css, SerializedStyles } from '@emotion/react';

import mediaQueries from './mediaQueries';

export const maxWidthVariable = 1200;

export const themeStyles: Theme = {
	colors: {
		primary: '#2e357d',
		primary80: '#2e357d80',
		secondary: '#1c2255',
		tertiary: '#2f2f2f',
		quaternary: '#17a7c6',
		bold: '#2d3482',
		error: '#d92d35',
		success: '#41b144',
		warning: '#fdbd39',
		border: '#b7b8c4',
	},
	mq: mediaQueries,
};

export const titleStyles = (theme: Theme): SerializedStyles => css`
	font-family: TTNorms, sans-serif;
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;

	& span {
		font-weight: normal;
	}

	${theme?.mq?.md} {
		font-size: 24px;
	}
`;

export const maxWidthStyles = css`
	max-width: ${maxWidthVariable}px;
	margin: 0 auto;
`;

export const rowCenterStyles = css`
	display: flex;
	align-items: center;
`;

export const colCenterStyles = css`
	${rowCenterStyles};
	flex-direction: column;
`;

export const flexCenterStyles = css`
	${rowCenterStyles};
	justify-content: center;
`;

export const btnResetStyles = css`
	outline: none;
	border: none;
	background-color: transparent;
	padding: 0;
`;
