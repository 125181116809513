import { createActions } from 'redux-actions';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';

export const getAuthToken = (state: any, refresh: boolean) =>
	refresh ? state.value.auth.refreshToken : state.value.auth.token;

export const createAsyncAction = (type: string) =>
	createActions(
		{
			REQUEST: (payload) => payload,
			SUCCESS: (payload) => payload,
			FAILURE: (error) => error,
		},
		{
			prefix: type,
			namespace: '_',
		}
	);

export const createEpic =
	(action, service: string, method: string, auth = false, refresh = false) =>
	(action$, state$, services) => {
		return action$.pipe(
			ofType(action.request().type),
			switchMap(({ payload }) => {
				return services[service][method](
					payload,
					auth ? getAuthToken(state$, refresh) : ''
				).pipe(
					map(({ response }) => {
						if (response.error_code === 0) {
							return action.success(response);
						} else {
							return action.failure(response);
						}
					}),
					catchError(({ response }) => of(action.failure(response)))
				);
			})
		);
	};
