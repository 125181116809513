import {
	authLoginAsyncAction,
	authChangePasswordAsyncAction,
	authPostFirstTimeLoginEmailAsyncAction,
	authPostFirstTimeLoginOtpAsyncAction,
	authPostFirstTimeLoginPasswordAsyncAction,
	authPutRefreshTokenAsyncAction,
	authLogoutAsyncAction,
	authPostForgotPasswordAsyncAction,
	authPostGetVerifyPinAsyncAction,
} from './authActions';
import { createEpic } from '../helpers';

export const authLoginEpic = createEpic(
	authLoginAsyncAction,
	'auth',
	'doLogin'
);

export const authLogoutEpic = createEpic(
	authLogoutAsyncAction,
	'auth',
	'doLogout',
	true
);

export const authChangePasswordEpic = createEpic(
	authChangePasswordAsyncAction,
	'auth',
	'postChangePassword',
	true
);

export const authPostFirstTimeLoginEmailEpic = createEpic(
	authPostFirstTimeLoginEmailAsyncAction,
	'auth',
	'postFirstTimeLoginEmail'
);

export const authPostFirstTimeLoginOtpEpic = createEpic(
	authPostFirstTimeLoginOtpAsyncAction,
	'auth',
	'postFirstTimeLoginOtp'
);

export const authPostFirstTimeLoginPasswordEpic = createEpic(
	authPostFirstTimeLoginPasswordAsyncAction,
	'auth',
	'postFirstTimeLoginPassword'
);

export const authPutRefreshTokenEpic = createEpic(
	authPutRefreshTokenAsyncAction,
	'auth',
	'putRefreshToken',
	true,
	true
);

export const authForgotPasswordEpic = createEpic(
	authPostForgotPasswordAsyncAction,
	'auth',
	'postForgotPassword'
);

export const authGetVerifyPinEpic = createEpic(
	authPostGetVerifyPinAsyncAction,
	'auth',
	'postGetVerifyPin'
);
