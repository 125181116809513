import { createAsyncAction } from '../helpers';

export const paymentGetInvoicesAsyncAction = createAsyncAction(
	'PAYMENT_GET_INVOICES'
);

export const paymentGetPaymentCardsAsyncAction = createAsyncAction(
	'GET_ALL_PAYMENT_CARD'
);

export const paymentAddPaymentCardAsyncAction =
	createAsyncAction('ADD_PAYMENT_CARD');

export const paymentUpdatePaymentCardAsyncAction = createAsyncAction(
	'UPDATE_PAYMENT_CARD'
);

export const paymentDeletePaymentCardAsyncAction = createAsyncAction(
	'DELETE_PAYMENT_CARD'
);

export const paymentGetPaymentHistoryAsyncAction = createAsyncAction(
	'GET_PAYMENT_HISTORY'
);

export const paymentGetPaymentDetailsAsyncActionAsync =
	createAsyncAction('GET_PAYMENT_DETAIL');

export const paymentMakePaymentAsyncAction = createAsyncAction('MAKE_PAYMENT');

export const paymentMakePayment2AsyncAction =
	createAsyncAction('MAKE_PAYMENT_2');

export const paymentGetMyBillsAsyncAction = createAsyncAction('GET_MY_BILLS');

export const paymentGetPaymentTransactionAsyncAction = createAsyncAction(
	'GET_PAYMENT_TRANSACTION'
);

export const paymentGetReceiptsAsyncAction = createAsyncAction('GET_RECEIPTS');
