import { api } from '../helpers';

export const getAccountFacilityPinCode = (_, token) => {
	return api('/Account/GetFacilityPinCode', 'GET', null, null, token);
};

export const postAccountUpdateProfile = (payload, token) => {
	return api('/Account/UpdateProfile', 'POST', payload, null, token);
};

export const updateAccountProfile = (payload, token) =>
	api('/Account/UpdateProfile', 'POST', payload, null, token);

export const getAccountProfile = (_, token) => {
	return api('/Account/GetProfile', 'GET', null, null, token);
};

export const postVerifyFacilityPin = (payload, token) => {
	return api('/Account/VerifyFacilityPinCode', 'POST', payload, null, token);
};

export const getLedgers = (_, token) => {
	return api('/Account/GetLedgers', 'GET', null, null, token);
};

export const getPointsHistory = (params, token) =>
	api('/Account/GetPointTransaction', 'GET', null, params, token);
