import { ajax, AjaxRequest } from 'rxjs/ajax';
import { isEmpty } from 'lodash';
import { stringify } from 'qs';

const API_URL = process.env.REACT_APP_API_URL;

export const api = (
	path: string,
	method = 'GET',
	payload: any,
	params: any,
	token = '',
	header = 'json',
	opts = {}
) => {
	const options: AjaxRequest = {
		url: API_URL + path,
		method: method.toUpperCase(),
		headers: {
			'client-type': 'web',
		},
		...opts,
	};

	if (!isEmpty(params)) {
		options['url'] =
			options['url'] + stringify(params, { addQueryPrefix: true });
	}

	if (payload) {
		options['body'] = payload;
	}

	if (token) {
		options['headers']['Authorization'] = 'Bearer ' + token;
	} else {
		options['headers'][
			'Authorization'
		] = `Basic ${process.env.REACT_APP_BASIC_AUTH}`;
	}

	if (header === 'json') {
		options['headers']['Content-Type'] = 'application/json';
	}

	return ajax(options);
};
