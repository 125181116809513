import { api } from '../helpers';

export const getFeaturedRewards = (_, token) => {
	return api(
		'/Reward/GetFeaturedRewardList',
		'GET',
		{ page: 1, keyword: '' },
		null,
		token
	);
};

export const getRewardsByCategory = (payload, token) => {
	return api('/Reward/GetRewardList', 'POST', payload, null, token);
};

export const redeemReward = (payload, token) =>
	api('/Reward/RedeemReward', 'POST', payload, null, token);

export const getMyWallet = (params, token) =>
	api('/Reward/GetMyWallet', 'GET', null, params, token);

export const getMyExtraSpaceWallet = (payload, token) =>
	api('/Reward/GetMyExtraSpaceWallet', 'POST', payload, null, token);
