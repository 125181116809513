import { ErrorBoundary } from 'react-error-boundary';
import styled from '@emotion/styled';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
`;

export const Fallback = () => (
	<Wrapper>
		<h1>Something went wrong.</h1>
	</Wrapper>
);

interface Props {
	handleReset?: () => void;
}

const Boundary = (props: React.PropsWithChildren<Props>) => {
	return (
		<ErrorBoundary FallbackComponent={Fallback} onReset={props.handleReset}>
			{props.children}
		</ErrorBoundary>
	);
};

export default Boundary;
