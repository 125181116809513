import { compose, combineReducers, createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { persistStore } from 'redux-persist';
// import logger from 'redux-logger';

import services from '../services';

import { authReducer } from './auth/authReducer';
import * as authEpics from './auth/authEpics';

import { accountReducer } from './account/accountReducer';
import * as accountEpics from './account/accountEpics';

import { postsReducer } from './posts/postsReducer';
import * as postsEpics from './posts/postsEpics';

import { masterReducer } from './master/masterReducer';
import * as masterEpics from './master/masterEpics';

import { rewardReducer } from './reward/rewardReducer';
import * as rewardEpics from './reward/rewardEpics';

import { paymentReducer } from './payment/paymentReducer';
import * as paymentEpics from './payment/paymentEpics';

import { notificationsReducer } from './notifications/notificationsReducer';
import * as notificationsEpics from './notifications/notificationsEpics';

import { feedbackReducer } from './feedback/feedbackReducer';
import * as feedbackEpics from './feedback/feedbackEpics';

import { faqReducer } from './faq/faqReducer';
import * as faqEpics from './faq/faqEpics';

import { contentReducer } from './content/contentReducer';
import * as contentEpics from './content/contentEpics';

import { appReducer } from './app/appReducer';

let composeEnhancers: typeof compose;
if (process.env.NODE_ENV === 'production') {
	composeEnhancers = compose;
} else {
	// eslint-disable-next-line
	// @ts-ignore
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const epicMiddleware = createEpicMiddleware({
	dependencies: services,
});

const rootReducer = combineReducers({
	auth: authReducer,
	account: accountReducer,
	posts: postsReducer,
	master: masterReducer,
	reward: rewardReducer,
	payment: paymentReducer,
	notifications: notificationsReducer,
	feedback: feedbackReducer,
	faq: faqReducer,
	content: contentReducer,
	app: appReducer,
});

const rootEpic = combineEpics(
	...Object.values(authEpics),
	...Object.values(accountEpics),
	...Object.values(postsEpics),
	...Object.values(masterEpics),
	...Object.values(rewardEpics),
	...Object.values(paymentEpics),
	...Object.values(notificationsEpics),
	...Object.values(feedbackEpics),
	...Object.values(faqEpics),
	...Object.values(contentEpics)
);

export const store = createStore(
	rootReducer,
	process.env.NODE_ENV === 'production'
		? composeEnhancers(applyMiddleware(epicMiddleware))
		: composeEnhancers(applyMiddleware(epicMiddleware))
	// : composeEnhancers(applyMiddleware(logger, epicMiddleware))
);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

epicMiddleware.run(rootEpic);
