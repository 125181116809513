import { createEpic } from '../helpers';
import {
	accountFacilityPinCodeAsyncAction,
	accountUpdateProfileAsyncAction,
	accountGetProfileAsyncAction,
	accountPostVerifyPinAsyncAction,
	accountGetLedgersAsyncAction,
	accountGetPointsHistoryAsyncAction,
} from './accountActions';

export const getAccountFacilityPinCodeEpic = createEpic(
	accountFacilityPinCodeAsyncAction,
	'account',
	'getAccountFacilityPinCode',
	true
);

export const postAccountUpdateProfileEpic = createEpic(
	accountUpdateProfileAsyncAction,
	'account',
	'postAccountUpdateProfile',
	true
);

export const getAccountProfileEpic = createEpic(
	accountGetProfileAsyncAction,
	'account',
	'getAccountProfile',
	true
);

export const postAccountVerifyPinEpic = createEpic(
	accountPostVerifyPinAsyncAction,
	'account',
	'postVerifyFacilityPin',
	true
);

export const getAccountLedgersEpic = createEpic(
	accountGetLedgersAsyncAction,
	'account',
	'getLedgers',
	true
);

export const getAccountPointsHistoryEpic = createEpic(
	accountGetPointsHistoryAsyncAction,
	'account',
	'getPointsHistory',
	true
);
