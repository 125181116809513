import { createEpic } from '../helpers';
import {
	postGetListAsyncAction,
	postGetDetailAsyncAction,
} from './postsActions';

export const postsGetEpic = createEpic(
	postGetListAsyncAction,
	'posts',
	'getPosts'
);

export const postGetDetailEpic = createEpic(
	postGetDetailAsyncAction,
	'posts',
	'getPostDetail'
);
