import { createAction } from 'redux-actions';

import type { AlertProps } from 'src/dtos/common.dto';

export const appToggleDrawerAction = createAction('TOGGLE_DRAWER');

export const appSetAlertAction = createAction<AlertProps>('APP_SET_ALERT');

export const appSetAnnouncementAction = createAction('SET_ANNOUNCEMENT');

export const appToggleNotificationDropdownAction = createAction(
	'TOGGLE_NOTIFICATION_DRAWER'
);
