import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
	postGetListAsyncAction,
	postGetDetailAsyncAction,
} from './postsActions';

import type {
	PostReducerDto as State,
	Payload,
} from '../../dtos/post/post-reducer.dto';

const initialState: State = {
	posts: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},

	postDetail: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},
};

const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>>,
		(state: State, { payload }: { payload: Payload }) => any
	>([
		[
			postGetListAsyncAction.request,
			(state) => ({
				...state,
				posts: { ...initialState.posts, loading: true },
			}),
		],
		[
			postGetListAsyncAction.success,
			(state, { payload }) => ({
				...state,
				posts: {
					...initialState.posts,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			postGetListAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				posts: {
					...initialState.posts,
					loading: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			postGetDetailAsyncAction.request,
			(state) => ({
				...state,
				postDetail: { ...initialState.postDetail, loading: true },
			}),
		],
		[
			postGetDetailAsyncAction.success,
			(state, { payload }) => ({
				...state,
				postDetail: {
					...initialState.postDetail,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			postGetDetailAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				postDetail: {
					...initialState.postDetail,
					loading: false,
					error: payload.error_msg,
				},
			}),
		],
	])
);

export const postsReducer = persistReducer(
	{
		key: 'posts',
		storage,
		blacklist: ['posts', 'postDetail'],
	},
	handleActions(reducerMap, initialState)
);
