import { createAsyncAction } from '../helpers';

export const rewardGetFeaturedRewardListAsyncAction = createAsyncAction(
	'REWARD_GET_FEATURED_REWARD_LIST'
);

export const rewardGetRewardListAsyncAction = createAsyncAction(
	'REWARD_GET_REWARD_LIST'
);

export const rewardRedeemRewardAsyncAction = createAsyncAction(
	'REWARD_REDEEM_REWARD'
);

export const rewardGetMyWalletAsyncAction = createAsyncAction(
	'REWARD_GET_MY_WALLET'
);

export const rewardGetMyExtraSpaceWalletAsyncAction = createAsyncAction(
	'REWARD_GET_MY_EXTRA_SPACE_WALLET'
);
