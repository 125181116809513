import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/feedback/feedback-reducer.dto';

import { feedbackCreateFeedbackAsyncAction } from './feedbackActions';

const initialState: State = {
	feedback: {
		loading: false,
		success: false,
		error: '',
	},
};

export const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>>,
		(state: State, { payload }: { payload: Payload }) => State
	>([
		[
			feedbackCreateFeedbackAsyncAction.request,
			(state) => ({
				feedback: {
					...state.feedback,
					loading: true,
				},
			}),
		],
		[
			feedbackCreateFeedbackAsyncAction.success,
			(state) => ({
				feedback: {
					...state.feedback,
					loading: false,
					success: true,
				},
			}),
		],
		[
			feedbackCreateFeedbackAsyncAction.failure,
			(state, { payload }) => ({
				feedback: {
					...state.feedback,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],
	])
);

export const feedbackReducer = persistReducer(
	{
		key: 'feedback',
		storage,
	},
	handleActions(reducerMap, initialState)
);
