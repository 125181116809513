import { createAction } from 'redux-actions';

import { createAsyncAction } from '../helpers';

export const notificationGetListAsyncAction = createAsyncAction(
	'NOTIFICATION_GET_LIST'
);

export const notificationMarkAsReadAsyncAction = createAsyncAction(
	'NOTIFICATION_MARK_AS_READ'
);

export const notificationDeleteAsyncAction = createAsyncAction(
	'NOTIFICATION_DELETE'
);

export const notificationSelectAction = createAction('NOTIFICATION_SELECT');
