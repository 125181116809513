import './SpinnerStyle.css';

import { Centered, Screen, Loading } from './Spinner.styles';

interface SpinnerProps {
	size?: number;
	color?: string;
}

const Spinner = ({ size = 80, color }: SpinnerProps) => {
	return <Loading data-testid="spinner" size={size} color={color} />;
};

export const CenteredSpinner = (props: SpinnerProps) => {
	return (
		<Centered>
			<Spinner {...props} />
		</Centered>
	);
};

export const FallbackSpinner = () => (
	<Screen>
		<Spinner />
	</Screen>
);

export default Spinner;
