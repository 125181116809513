import { api } from '../helpers';

export const getNotifications = (params, token) =>
	api('/Notifications/GetList', 'GET', null, params, token);

export const markNotificationAsRead = (params, token) =>
	api('/Notifications/IsRead', 'PUT', null, params, token);

export const deleteNotifications = (payload, token) =>
	api('/Notifications/DeleteNotifications', 'POST', payload, null, token);
