import { api } from '../helpers';

export const doLogin = (payload) =>
	api('/Authentication/Login', 'POST', payload);

export const doLogout = (_, token) =>
	api('/Authentication/Logout', 'PUT', { deviceToken: token }, null);

export const postChangePassword = (payload, token) =>
	api('/Authentication/ChangePassword', 'POST', payload, null, token);

export const postFirstTimeLoginEmail = (payload) =>
	api('/Authentication/FirstLogin', 'POST', payload);

export const postGetVerifyPin = (payload) =>
	api('/Authentication/GetVerifyPin', 'POST', payload, null);

export const postFirstTimeLoginOtp = (payload) =>
	api('/Authentication/VerifyOTP', 'POST', payload);

export const postFirstTimeLoginPassword = (payload) =>
	api('/Authentication/SetPassword', 'POST', payload);

export const putRefreshToken = (payload, token) =>
	api('/Authentication/RefreshToken', 'PUT', payload, null, token);

export const postForgotPassword = (payload) =>
	api('/Authentication/ForgotPassword', 'POST', payload, null);
