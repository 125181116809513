import { createEpic } from '../helpers';

import {
	getContentAsyncAction,
	getContactUsAsyncAction,
} from './contentActions';

export const getContentEpic = createEpic(
	getContentAsyncAction,
	'content',
	'getContent'
);

export const getContactUsEpic = createEpic(
	getContactUsAsyncAction,
	'content',
	'getContactUs',
	true
);
