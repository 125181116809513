import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/app/app-reducer.dto';

import {
	appToggleDrawerAction,
	appSetAlertAction,
	appSetAnnouncementAction,
	appToggleNotificationDropdownAction,
} from './appActions';

const initialState: State = {
	drawerOpen: false,
	alert: null,
	annoucement: '',
	notificationOpen: false,
};

export const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>>,
		(state: State, { payload }: { payload: Payload }) => any
	>([
		[
			appToggleDrawerAction,
			(state, { payload }) => ({
				...state,
				drawerOpen: payload,
			}),
		],

		[
			appSetAlertAction,
			(state, { payload }) => ({
				...state,
				alert: payload,
			}),
		],

		[
			appSetAnnouncementAction,
			(state, { payload }) => ({
				...state,
				annoucement: payload,
			}),
		],

		[
			appToggleNotificationDropdownAction,
			(state, { payload }) => ({
				...state,
				notificationOpen: payload,
			}),
		],
	])
);

export const appReducer = persistReducer(
	{
		key: 'app',
		storage,
		whitelist: [],
		blacklist: ['drawerOpen', 'annoucement'],
	},
	handleActions(reducerMap, initialState)
);
