import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/content/content-reducer.dto';

import { getContactUsAsyncAction } from './contentActions';

const initialState: State = {
	contactUs: {
		loading: false,
		success: false,
		error: '',
		data: [],
	},
};

export const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>>,
		(state: State, { payload }: { payload: Payload }) => any
	>([
		[
			getContactUsAsyncAction.request,
			(state) => ({
				...state,
				contactUs: { ...state.contactUs, loading: true },
			}),
		],
		[
			getContactUsAsyncAction.success,
			(state, { payload }) => ({
				...state,
				contactUs: {
					...state.contactUs,
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			getContactUsAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				contactUs: {
					...state.contactUs,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],
	])
);

export const contentReducer = persistReducer(
	{
		key: 'content',
		storage,
		whitelist: ['contactUs'],
	},
	handleActions(reducerMap, initialState)
);
