import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/faq/faq-reducer.dto';

import { faqGetFaqsAsyncAction } from './faqActions';

const initialState: State = {
	faqs: {
		loading: false,
		success: false,
		error: '',
		data: [],
	},
};

export const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>>,
		(state: State, { payload }: { payload: Payload }) => any
	>([
		[
			faqGetFaqsAsyncAction.request,
			(state) => ({
				...state,
				faqs: { ...state.faqs, loading: true },
			}),
		],
		[
			faqGetFaqsAsyncAction.success,
			(state, { payload }) => ({
				...state,
				faqs: {
					...state.faqs,
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			faqGetFaqsAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				faqs: {
					...state.faqs,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],
	])
);

export const faqReducer = persistReducer(
	{
		key: 'faq',
		storage,
		blacklist: ['faqs'],
	},
	handleActions(reducerMap, initialState)
);
