//import { from, of } from 'rxjs';
//import { delay } from 'rxjs/operators';

import { api } from '../helpers';

//import { failedResponse as response } from '../../utils/constants';

export const getInvoices = (_, token) => {
	return api('/Payment/GetInvoices', 'GET', null, null, token);
};

export const getCards = (_, token) =>
	api('/Payment/GetAllPaymentCard', 'GET', null, null, token);

export const addCard = (payload, token) =>
	api('/Payment/AddPaymentCard', 'POST', payload, null, token);

export const updateCard = (payload, token) =>
	api('/Payment/UpdatePaymentCard', 'PUT', payload, null, token);

export const getPaymentHistory = (_, token) =>
	api('/Payment/GetPaymentHistory', 'GET', null, null, token);

export const paymentGetPaymentDetailsAsyncAction = (params, token) =>
	api('/Payment/GetPaymentDetail', 'GET', null, params, token);

export const downloadReceiptPdf = (params, token) =>
	api('/Payment/DownloadReceiptPdf', 'GET', null, params, token, 'json', {
		responseType: 'blob',
	});

export const downloadInvoicePdf = (params, token) =>
	api('/Payment/DownloadInvoicePdf', 'GET', null, params, token, 'json', {
		responseType: 'blob',
	});

export const makePayment = (payload, token) =>
	api('/Payment/MakePayment', 'POST', payload, null, token);

// for test
//export const makePayment2 = () => from(of({ response })).pipe(delay(3000));
export const makePayment2 = (payload, token) =>
	api('/Payment/MakePayment2', 'POST', payload, null, token);

export const getWallets = (params, token) =>
	api('/Reward/GetMyWallet', 'GET', null, params, token);

export const deleteCard = (payload, token) =>
	api('/Payment/DeletePaymentCard', 'POST', payload, null, token);

export const getMyBills = (_, token) =>
	api('/Payment/GetMyBills', 'GET', null, null, token);

export const getPaymentTransaction = (_, token) =>
	api('/Payment/GetPaymentTransaction', 'GET', null, null, token);

export const getReceipts = (_, token) =>
	api('/Payment/GetReceipts', 'GET', null, null, token);
