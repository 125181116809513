import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/reward/reward-reducer.dto';
import type { GetRewardListPayload } from '../../dtos/reward/reward.dto';

import {
	rewardGetFeaturedRewardListAsyncAction,
	rewardGetRewardListAsyncAction,
	rewardGetMyWalletAsyncAction,
	rewardRedeemRewardAsyncAction,
} from './rewardActions';

const initialState: State = {
	featuredRewards: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},

	rewardsByCategory: {
		loading: false,
		success: false,
		error: '',
		data: null,
		categoryId: null,
	},

	myWallet: {
		loading: false,
		success: false,
		error: '',
		data: [],
	},

	redeemReward: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},
};

const reducerMap = Object.fromEntries(
	new Map<ActionFunctionAny<Action<any>>, (state: State, { payload }) => any>(
		[
			[
				rewardGetFeaturedRewardListAsyncAction.request,
				(state) => ({
					...state,
					featuredRewards: {
						...state.featuredRewards,
						loading: true,
					},
					rewardsByCategory: {
						...state.rewardsByCategory,
						categoryId: null,
					},
				}),
			],
			[
				rewardGetFeaturedRewardListAsyncAction.success,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					featuredRewards: {
						...state.featuredRewards,
						success: true,
						loading: false,
						data: payload.data,
					},
				}),
			],
			[
				rewardGetFeaturedRewardListAsyncAction.failure,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					featuredRewards: {
						...state.featuredRewards,
						loading: false,
						error: payload.error_msg,
					},
				}),
			],

			[
				rewardGetRewardListAsyncAction.request,
				(state, { payload }: { payload: GetRewardListPayload }) => ({
					...state,
					rewardsByCategory: {
						...initialState.rewardsByCategory,
						loading: true,
						categoryId: payload.category,
					},
				}),
			],
			[
				rewardGetRewardListAsyncAction.success,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					rewardsByCategory: {
						...state.rewardsByCategory,
						loading: false,
						success: true,
						data: payload.data,
					},
				}),
			],
			[
				rewardGetRewardListAsyncAction.failure,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					rewardsByCategory: {
						...state.rewardsByCategory,
						loading: false,
						error: payload.error_msg,
					},
				}),
			],

			[
				rewardGetMyWalletAsyncAction.request,
				(state) => ({
					...state,
					myWallet: {
						...state.myWallet,
						loading: true,
					},
				}),
			],
			[
				rewardGetMyWalletAsyncAction.success,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					myWallet: {
						...state.myWallet,
						loading: false,
						success: true,
						data: payload.data,
					},
				}),
			],
			[
				rewardGetMyWalletAsyncAction.failure,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					myWallet: {
						...state.myWallet,
						loading: false,
						error: payload.error_msg,
					},
				}),
			],

			[
				rewardRedeemRewardAsyncAction.request,
				(state) => ({
					...state,
					redeemReward: {
						...state.redeemReward,
						loading: true,
						success: false,
						error: '',
						data: [],
					},
				}),
			],
			[
				rewardRedeemRewardAsyncAction.success,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					redeemReward: {
						...state.redeemReward,
						loading: false,
						success: true,
						data: payload.data,
						error: '',
					},
				}),
			],
			[
				rewardRedeemRewardAsyncAction.failure,
				(state, { payload }: { payload: Payload }) => ({
					...state,
					redeemReward: {
						...state.redeemReward,
						loading: false,
						data: [],
						error: payload.error_msg,
						success: false,
					},
				}),
			],
		]
	)
);

export const rewardReducer = persistReducer(
	{
		key: 'reward',
		storage,
		blacklist: ['redeemReward'],
	},
	handleActions(reducerMap, initialState)
);
