import './App.scss';
import React, { useState, createContext } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider, Theme } from '@emotion/react';
import { store, persistor } from './store/store';
import { FallbackSpinner } from './components/App/Spinner/Spinner';
import { themeStyles } from './theme/theme';
import ErrorBoundary from './ErrorBoundary';
import RouteChangesListener from './components/App/RouteChangesListener/RouteChangesListener';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const mainTheme: Theme = themeStyles;
export const ThemeContext = createContext(undefined);

const App = () => {
	const [activeTheme, setActiveTheme] = useState('light');

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<HashRouter>
					<RouteChangesListener />

					<ThemeContext.Provider
						value={{ activeTheme, setActiveTheme }}
					>
						<ThemeContext.Consumer>
							{(theme) => (
								<ThemeProvider
									theme={{ ...mainTheme, ...theme }}
								>
									<React.Suspense
										fallback={<FallbackSpinner />}
									>
										<ErrorBoundary>
											<Switch>
												<Route
													path="/"
													//name="Home"
													render={(props) => (
														<DefaultLayout
															{...props}
														/>
													)}
												/>
											</Switch>
										</ErrorBoundary>
									</React.Suspense>
								</ThemeProvider>
							)}
						</ThemeContext.Consumer>
					</ThemeContext.Provider>
				</HashRouter>
			</PersistGate>
		</Provider>
	);
};

export default App;
