import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from 'src/dtos/master/master-reducer.dto';

import { masterGetMasterAsyncAction } from './masterActions';

const initialState: State = {
	master: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},
};

const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>>,
		(state: State, { payload }: { payload: Payload }) => State
	>([
		[
			masterGetMasterAsyncAction.request,
			(state) => ({
				master: {
					...state.master,
				},
			}),
		],
		[
			masterGetMasterAsyncAction.success,
			(state, { payload }) => ({
				master: {
					...state.master,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			masterGetMasterAsyncAction.failure,
			(state, { payload }) => ({
				master: {
					...state.master,
					loading: false,
					error: payload.error_msg,
				},
			}),
		],
	])
);

export const masterReducer = persistReducer(
	{
		key: 'master',
		storage,
	},
	handleActions(reducerMap, initialState)
);
