import { handleActions, ActionFunctionAny, Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/account/account-reducer.dto';

import {
	accountFacilityPinCodeAsyncAction,
	accountUpdateProfileAsyncAction,
	accountGetProfileAsyncAction,
	accountPostVerifyPinAsyncAction,
	accountGetLedgersAsyncAction,
	accountGetPointsHistoryAsyncAction,
	decrementNotificiationOnReadAction,
} from './accountActions';

const initialState: State = {
	isOtpAnswered: false,

	profile: null,

	profileUpdate: {
		loading: false,
		success: false,
		error: '',
	},

	ledgers: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},

	pointsHistory: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},

	facilityPinCodes: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},
};

const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>>,
		(state: State, { payload }: { payload: Payload }) => any
	>([
		[
			accountFacilityPinCodeAsyncAction.request,
			(state) => ({
				...state,
				isOtpAnswered: false,
			}),
		],

		[
			accountUpdateProfileAsyncAction.request,
			(state) => ({
				...state,
				profileUpdate: {
					...state.profileUpdate,
					loading: true,
				},
			}),
		],
		[
			accountUpdateProfileAsyncAction.success,
			(state) => ({
				...state,
				profileUpdate: {
					...state.profileUpdate,
					success: true,
					error: '',
				},
			}),
		],
		[
			accountUpdateProfileAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				profileUpdate: {
					...state.profileUpdate,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			accountGetProfileAsyncAction.request,
			(state) => ({
				...state,
				isOtpAnswered: false,
				facilityPinCodes: {
					...state.facilityPinCodes,
					error: '',
				},
			}),
		],
		[
			accountGetProfileAsyncAction.success,
			(state, { payload }) => ({
				...state,
				profile: payload.data,
			}),
		],
		[
			accountGetProfileAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				profile: payload.data,
			}),
		],

		[
			accountPostVerifyPinAsyncAction.request,
			(state) => ({
				...state,
				isOtpAnswered: false,
				facilityPinCodes: {
					...state.facilityPinCodes,
					loading: true,
				},
			}),
		],
		[
			accountPostVerifyPinAsyncAction.success,
			(state, { payload }) => ({
				...state,
				isOtpAnswered: true,
				facilityPinCodes: {
					...state.facilityPinCodes,
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			accountPostVerifyPinAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				isOtpAnswered: false,
				facilityPinCodes: {
					...state.facilityPinCodes,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			accountGetLedgersAsyncAction.request,
			(state) => ({
				...state,
				ledgers: {
					...initialState.ledgers,
					loading: true,
				},
			}),
		],
		[
			accountGetLedgersAsyncAction.success,
			(state, { payload }) => ({
				...state,
				ledgers: {
					...initialState.ledgers,
					success: true,
					loading: false,
					data: payload.data,
				},
			}),
		],
		[
			accountGetLedgersAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				ledgers: {
					...initialState.ledgers,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			accountGetPointsHistoryAsyncAction.request,
			(state) => ({
				...state,
				pointsHistory: {
					loading: true,
				},
			}),
		],
		[
			accountGetPointsHistoryAsyncAction.success,
			(state, { payload }) => ({
				...state,
				pointsHistory: {
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			accountGetPointsHistoryAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				pointsHistory: {
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			decrementNotificiationOnReadAction,
			(state) => ({
				...state,
				profile: {
					...state.profile,
					newNotifications:
						Number(state.profile.newNotifications) === 0
							? 0
							: Number(state.profile.newNotifications) - 1,
				},
			}),
		],
	])
);

export const accountReducer = persistReducer(
	{
		key: 'account',
		storage,
		blacklist: ['profile', 'isOtpAnswered'],
		whitelist: ['ledgers'],
	},
	handleActions(reducerMap, initialState)
);
