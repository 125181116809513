interface MediaQueries {
	xs: string;
	sm: string;
	md: string;
	lg: string;
	xl: string;
	xxl: string;
}

const mediaQueries: MediaQueries = {
	xs: '@media only screen and (max-width: 320px)',
	sm: '@media only screen and (max-width: 640px)',
	md: '@media only screen and (max-width: 768px)',
	lg: '@media only screen and (max-width: 1024px)',
	xl: '@media only screen and (max-width: 1280px)',
	xxl: '@media only screen and (max-width: 1536px)',
};

export default mediaQueries;
